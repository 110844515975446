import { getPayloadObject, getTelemetryAttributes, ITelemetryContent, TelemetryConstant } from '@msdyn365-commerce-modules/utilities';
import React from 'react';
export * from './account-statement-request-modal';
export * from './account-actions';

interface IAccountLabel {
    name: string;
    accountNumber: string;
    createdOn: string;
    customerSincePrefix: string;
}

interface IAccountValues {
    availableCredit: string;
    availableCreditLabel: string;
}

interface ICreditSummaryLabel {
    availableCredit: string;
    balanceOwed: string;
    creditExcess: string | null;
}

interface ICreditSummaryValues {
    availableCredit: string;
    balanceOwed: string;
    creditExcess: string | null;
}

interface IBackButton {
    link: string;
    label: string;
    telemetryContent?: ITelemetryContent;
}

export const AccountLabels: React.FC<IAccountLabel> = ({ name, accountNumber, customerSincePrefix, createdOn }) => (
    <div className='ms-account-customer-balance__account__details'>
        <h4>{name}</h4>
        <div className='ms-account-customer-balance__account-number'>#{accountNumber}</div>
        <div className='ms-account-customer-balance__created-on'>
            {customerSincePrefix} {createdOn}
        </div>
    </div>
);

export const AccountValues: React.FC<IAccountValues> = ({ availableCredit, availableCreditLabel }) => (
    <div className='ms-account-customer-balance__account__credit'>
        <div className='ms-account-customer-balance__available-credit-label'>{availableCreditLabel}</div>
        <div className='ms-account-customer-balance__available-credit-amount'>{availableCredit}</div>
    </div>
);

export const CreditSummaryLabels: React.FC<ICreditSummaryLabel> = ({ availableCredit, balanceOwed, creditExcess }) => (
    <div className='ms-account-customer-balance__credit-summary__labels'>
        <div className='ms-account-customer-balance__available-credit-label'>{availableCredit}</div>
        <div className='ms-account-customer-balance__balance-owed-label'>{balanceOwed}</div>
        <div className='ms-account-customer-balance__credit-excess-label'>{creditExcess}</div>
    </div>
);

export const CreditSummaryValues: React.FC<ICreditSummaryValues> = ({ availableCredit, balanceOwed, creditExcess }) => (
    <div className='ms-account-customer-balance__credit-summary__values'>
        <div className='ms-account-customer-balance__available-credit-label'>{availableCredit}</div>
        <div className='ms-account-customer-balance__balance-owed-label'>{balanceOwed}</div>
        {creditExcess && <div className='ms-account-customer-balance__excess-credit-label'>{creditExcess}</div>}
    </div>
);

export const BackButton: React.FC<IBackButton> = ({ link, label, telemetryContent }) => {
    const payLoad = getPayloadObject('click', telemetryContent!, TelemetryConstant.BackToShopping);
    const attributes = getTelemetryAttributes(telemetryContent!, payLoad);
    return (
    <a className='ms-account-loyalty__home-link msc-btn' href={link} {...attributes}>
        {label}
    </a>
    );
};
